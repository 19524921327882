import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { AnalyticsConfig } from "@egds/react-core/utils";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { viewTypes } from "../hotelRecentReviewsUtils";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

import { HotelCard, HotelRecentReviewsViewProps, ModuleTitle, TopLevelComponentClassName } from "../typings";
import { withStores } from "stores";
import { observer } from "mobx-react";
import IsomorphicCarousel from "src/components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { HotelRecentReviewsFlexModuleResult } from "src/typings/microserviceModels/hotel-recent-reviews-flex-module";
import { getFmId } from "src/stores/ExperienceTemplateStore";

/**
 * This component is rendered for certain views of the module `hotel-recent-reviews-flex-module`, validate the flexComponentMap
 * to validate the different views that it supports. Current views:
 *     - thumbnail-image-left-card
 *     - header-image-card
 * @param props of type HotelRecentReviewsProps
 */
const HotelRecentReviewsView = withStores(
  "analytics",
  "flexModuleModelStore"
)(
  observer((props: HotelRecentReviewsViewProps) => {
    const { templateComponent, flexModuleModelStore } = props;

    if (!templateComponent) {
      return null;
    }

    const {
      metadata: { id },
      config,
    } = templateComponent;
    const { fmTitleId, view } = config;
    const fmId = getFmId(templateComponent);
    const { hideTitle } = templateComponent.config;
    const model = flexModuleModelStore.getModel(id) as HotelRecentReviewsFlexModuleResult | null;
    /* istanbul ignore next */
    if (!model || !view) {
      return null;
    }
    const { hotelReviewList } = model;

    if (!hotelReviewList?.length) {
      return null;
    }

    const { formatText } = useLocalization();
    const hotelCardKeyHelper = new ItemKeyHelper("hotelRecentReviewsCard");

    /* istanbul ignore next */
    const carouselAnalytics: AnalyticsConfig = {
      id: "LP.HotelRecentReviews",
      callback: (theId, description) => {
        const flexTrackingInfo: FlexTrackingInfo = {
          moduleName: theId,
          action: Action.SCROLL,
          linkName: description,
        };
        sendDelayedTrackEvent(flexTrackingInfo, props.analytics);
      },
    };

    const ModuleTitle = viewTypes.moduleTitle[view as keyof ModuleTitle];
    const HotelCard = viewTypes.hotelCard[view as keyof HotelCard];

    return (
      <EGDSSpacing padding={{ block: "three" }}>
        <div
          className={viewTypes.topLevelComponentClassName[view as keyof TopLevelComponentClassName]}
          id={id}
          data-fm={fmId}
          data-fm-title-id={fmTitleId}
        >
          {!hideTitle && <ModuleTitle model={model} />}
          <EGDSSpacing margin={{ blockstart: "three" }}>
            <IsomorphicCarousel
              analytics={carouselAnalytics}
              buttonText={{
                nextButton: formatText("carousel.item.next"),
                prevButton: formatText("carousel.item.prev"),
              }}
              carouselName="hotelRecentReviewsCarousel"
              itemsVisible={{ lg: 3, md: 2, sm: 1 }}
              pageBy={1}
              peek
            >
              {hotelReviewList.map((hotel) => (
                <HotelCard key={hotelCardKeyHelper.next()} model={model} hotel={hotel} />
              ))}
            </IsomorphicCarousel>
          </EGDSSpacing>
        </div>
      </EGDSSpacing>
    );
  })
);

export default HotelRecentReviewsView;
